export const formatTaskNumber = (taskNumber) => {
  const userData = localStorage.getItem('userData');
  if (userData) {
    const user = JSON.parse(userData);
    var currUserStaion = user.station;
    if (currUserStaion === 105) {
      if (taskNumber >= 100) {
        const remainder = taskNumber % 100;
        return remainder + 1;
      } else {
        return taskNumber;
      }
    } else {
      if (taskNumber >= 100) {
        const remainder = taskNumber % 100;
        return remainder;
      } else {
        return taskNumber;
      }
    }
  }
};
