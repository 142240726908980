import React, { useCallback, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome, faUser } from '@fortawesome/free-solid-svg-icons';
import api from '../api/api';
import Navbar from '../components/Navbar';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';
import { CButton, CModal, CModalBody, CModalFooter } from '@coreui/react';
import Select from 'react-select';
import 'virtual-select-plugin/dist/virtual-select.min.css';
import 'virtual-select-plugin/dist/virtual-select.min.js';

const CreateUsers = () => {
  const [displaySidebar, setDisplaySidebar] = useState(true);
  const [selectColony, setSelectColony] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectStation, setSelectStation] = useState();
  const [onComplain, setonComplain] = useState();
  const [url, setUrl] = useState('');
  const [scoreNow, setScoreNow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorModalFlag, setErrorModalFlag] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [occupiedUsernames, setOccupiedUsernames] = useState([]);
  const [showErrorMsg, setShowErrorMsg] = useState('');
  const [userType, setUserType] = useState();
  const [userStation, setUserStation] = useState();
  const [stations, setStations] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [posts, setPosts] = useState('');
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [assignedStation, setAssignedStation] = useState([]);
  const [showNotAvailableUsernameModal, setShowNotAvailableUsernameModal] =
    useState(false);

  const navigate = useNavigate();

  library.add(faHome, faUser);

  const toggleSideBar = () => {
    setDisplaySidebar(!displaySidebar);
  };

  const fetchStations = useCallback(() => {
    const ret_stations = [];
    api
      .get('/station/stationslists/', {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      })
      .then((response) => {
        response.data.forEach((station) => {
          ret_stations.push({
            value: station.station_id.toString(),
            label: station.station_name,
          });
        });
        ret_stations.sort((a, b) => a.label.localeCompare(b.label));
        setStations(ret_stations);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  const fetchTask = useCallback(() => {
    setLoading(true);
    api
      .get(`/complain/complain?date=${formattedDate}`, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      })
      .then((response) => {
        const tasksData = response.data.tasks.map((task) => ({
          value: task[0].toString(),
          label: task[2],
        }));
        setTasks(tasksData);
        setLoading(false);
      })
      .catch((error) => {
        console.error({ error });
        setLoading(false);
      });
  }, [formattedDate]);

  const submithandler = (e) => {
    e.preventDefault();
    if (!username || !posts || !mobileNumber || !assignedStation.length) {
      setShowErrorMsg('Please fill all the mandatory fields');
      setShowModal(true);
      return;
    }

    const formData = {
      username,
      email,
      mobile_number: mobileNumber,
      whatsapp_number: mobileNumber,
      posts: posts.split(',').map((post) => ({ content: post.trim() })),
      assigned_tasks: assignedTasks.map((task) => ({ task_id: task })),
      assigned_station: assignedStation.map((station) => ({
        station_code: station,
      })),
    };

    console.log('Form Data:', formData);

    api
      .post('notified-user/add/', formData, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      })
      .then((response) => {
        if (response.status === 201) {
          setShowModal(true);
          setShowErrorMsg('User created successfully');
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 991) {
        setDisplaySidebar(false);
      } else {
        setDisplaySidebar(true);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userType = userData.user_type;
    const userStation = userData.station_name;
    setUserStation(userStation);
    setUserType(userType);
    fetchStations();
    fetchTask();
    if (userType !== 's2 admin') {
      console.log('wrong userType', userType);
      console.log('Only Admins can create users for now');
      navigate('/home', { replace: true });
    }
  }, [userType, navigate, fetchStations, fetchTask]);

  useEffect(() => {
    try {
      if (document.querySelector('#station')) {
        document.querySelector('#station').destroy();
      }
    } catch (error) {
      console.log(error);
    }

    window.VirtualSelect.init({
      ele: '#station',
      search: true,
      options: stations,
      optionSelectedText: 'Station Selected',
      optionsSelectedText: 'Stations Selected',
      allOptionsSelectedText: 'All Stations',
      searchPlaceholderText: 'Select all',
      alwaysShowSelectedOptionsCount: true,
    });

    if (document.querySelector('#station')) {
      document
        .querySelector('#station')
        .addEventListener('change', function () {
          let value = this.value;
          if (!Array.isArray(value)) {
            value = [value];
          }
          setAssignedStation(value);
        });
    }
  }, [stations]);

  useEffect(() => {
    try {
      if (document.querySelector('#task')) {
        document.querySelector('#task').destroy();
      }
    } catch (error) {
      console.log(error);
    }

    window.VirtualSelect.init({
      ele: '#task',
      search: true,
      options: tasks,
      optionSelectedText: 'task Selected',
      optionsSelectedText: 'tasks Selected',
      allOptionsSelectedText: 'All tasks',
      searchPlaceholderText: 'Select all',
      alwaysShowSelectedOptionsCount: true,
    });

    if (document.querySelector('#task')) {
      document.querySelector('#task').addEventListener('change', function () {
        let value = this.value;
        if (!Array.isArray(value)) {
          value = [value];
        }
        setAssignedTasks(value);
      });
    }
  }, [tasks]);

  return (
    <div className="page-body">
      <CModal visible={showModal} backdrop="static" aria-labelledby="UserModal">
        <CModalBody>
          <h5>{showErrorMsg}</h5>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => {
              setShowModal(false);
              window.location.reload();
            }}
          >
            Ok
          </CButton>
        </CModalFooter>
      </CModal>
      <Navbar
        displaySidebar={displaySidebar}
        toggleSideBar={toggleSideBar}
        visibilityData={{ visibleModal, setVisibleModal }}
        urlData={{ url, setUrl }}
        scoreNowData={{ scoreNow, setScoreNow }}
        complainData={{ onComplain, setonComplain }}
        stationChange={{ selectStation, setSelectStation }}
        colonyChange={{ selectColony, setSelectColony }}
      />
      <div
        style={{
          marginLeft: displaySidebar
            ? window.innerWidth > 991
              ? '230px'
              : '0px'
            : '0px',
          paddingTop: '100px',
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <div>
            <div className="data-modal mod-visible">
              {occupiedUsernames ? (
                <div>
                  <div className="header-container1 pb-2 max-sm:px-2 mb-3">
                    <h4 className="text-center underline py-1 text-4xl">
                      Create a new User
                    </h4>
                  </div>
                  <div className="header-container1 pb-2 max-sm:px-2 mb-3">
                    <div className="data-modal mod-visible">
                      <form className="space-y-2" onSubmit={submithandler}>
                        <div className="flex flex-col justify-start items-start text-center">
                          <label className="p-1 font-semibold">Username:</label>
                          <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="w-full p-1"
                          />
                          {showNotAvailableUsernameModal && (
                            <div className="flex flex-col justify-start items-start text-left border-red-500 bg-red-200 px-1 py-2 w-full rounded-lg mt-1">
                              <span>
                                The username entered is not available.
                              </span>
                              <span>Other occupied usernames are:</span>
                              <span className="w-full flex flex-wrap">
                                {occupiedUsernames.map((username) => (
                                  <span className="px-0.5" key={username}>
                                    {username},
                                  </span>
                                ))}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="flex flex-col justify-start items-start text-center">
                          <label className="p-1 font-semibold">Email:</label>
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full p-1"
                          />
                        </div>
                        <div className="flex flex-col justify-start items-start text-center">
                          <label className="p-1 font-semibold">
                            Mobile Number:
                          </label>
                          <input
                            type="text"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            className="w-full p-1"
                          />
                        </div>
                        <div className="flex flex-col justify-start items-start text-center">
                          <label className="p-1 font-semibold">
                            Assigned Tasks:
                          </label>
                          {/* <Select
                            options={tasks}
                            value={tasks.filter(task => assignedTasks.includes(task.value))}
                            onChange={(selectedOptions) => setAssignedTasks(selectedOptions.map(option => option.value))}
                            isMulti
                            className="w-full p-1"
                          /> */}
                          <select
                            placeholder="Select tasks"
                            id="task"
                            multiple
                            className="multiselect mt-2 "
                            multiselect-search="true"
                            multiselect-select-all="true"
                            multiselect-max-items="1"
                            name="task"
                          ></select>
                        </div>
                        <div className="flex flex-col justify-start items-start text-center">
                          <label className="p-1 font-semibold">Posts:</label>
                          <input
                            type="text"
                            value={posts}
                            onChange={(e) => setPosts(e.target.value)}
                            className="w-full p-1"
                          />
                        </div>
                        <div className="flex flex-col justify-start items-start text-center">
                          <label className="p-1 font-semibold">
                            Assigned Station:
                          </label>
                          {/* <Select
                            options={stations}
                            value={stations.filter(station => assignedStation.includes(station.value))}
                            onChange={(selectedOptions) => setAssignedStation(selectedOptions.map(option => option.value))}
                            isMulti
                            className="w-full p-1"
                          /> */}

                          <select
                            placeholder="Select Stations"
                            id="station"
                            multiple
                            className="multiselect mt-2 "
                            multiselect-search="true"
                            multiselect-select-all="true"
                            multiselect-max-items="1"
                            name="station"
                          ></select>
                        </div>
                        <div className="flex flex-col justify-center items-center text-center">
                          <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                          >
                            Create User
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              ) : (
                <div>No data found for the usernames</div>
              )}
              <div className="flex justify-center items-center text-center w-full">
                <button
                  className="p-2 mb-8 btn btn-secondary border-2 w-40 rounded-lg"
                  onClick={() => navigate('/notified_users')}
                >
                  Show All Users
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateUsers;
