import React, { useCallback, useEffect, useState } from 'react';
import api from '../api/api';
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faPen } from '@fortawesome/free-solid-svg-icons'


export const CurrCommentReview = ({ newState, preComData }) => {
  const [comments, setComments] = useState([]);
  const [editCommentModel, setEditCommentModel] = useState(false)
  const [commentToEdit, setCommentToEdit] = useState({})
  const [updatedComment, setUpdatedComment] = useState("")
  const [loader, setLoader] = useState({flag:false,button:""})
  const [msg, setMsg] = useState("")
  const fetchInfo = useCallback(async () => {
    if (newState.task_num === undefined) return;
    if (newState.shift_num === undefined) return;
    if (newState.occurrence === undefined) return;
    const apiUrl = `/api/comment/add/${newState.date}/${newState.task_num}/${newState.shift_num}/${newState.occurrence}`;
    api
      .get(apiUrl, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setComments(response.data);
          if(response.data.length > 0){
            preComData.setPrevComments(response.data)
          }else{
            preComData.setPrevComments(null)
          }
        } else {
          throw new Error('Failed to submit comment data');
        }
      })
      .catch((error) => {
        setComments([]);
        preComData.setPrevComments(null)
        console.log(
          error,
          'No comments found for the specified task, shift, and occurrence.'
        );
      });
  }, [newState]);
  const dateConverter = (date) => {
    if (date === undefined) return '';
    return new Date(date).toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });
  };

  const editComment = (comment) => {
    setEditCommentModel(true)
    setCommentToEdit(comment)
    setUpdatedComment(comment.text)
  }

  const deleteComment = () => {
    setLoader({
      flag: true,
      button: 'delete'
    })
    const apiUrl = `/api/comment/delete/${commentToEdit.id}`
    api
      .delete(apiUrl, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setMsg('comment deleted')
        } else {
          setMsg('failed to delete')
        }
      })
      .catch((error) => {
        console.error(error, "Failed to delete")
        setMsg('failed to delete')
      }).finally(() => {
        setLoader({
          flag: false,
          button: ''
        })
        setTimeout(() => {
          fetchInfo()
          setEditCommentModel(false)
          setMsg('')
        },2000)
      })
  }

  const updateComment = () => {
    setLoader({
      flag: true,
      button: 'update'
    })
    const apiUrl = `/api/comment/update/${commentToEdit.id}`
    api
      .put(apiUrl, {"text" : updatedComment}, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setMsg('comment updated')
        } else {
          setMsg('Failed to update')
        }
      })
      .catch((error) => {
        console.error(error, "Failed to update")
        setMsg('Failed to update')
      }).finally(() => {
        setLoader({
          flag: false,
          button: ''
        })
        setTimeout(() => {
          fetchInfo()
          setEditCommentModel(false)
          setMsg('')
        },2000)
      })
  }

  useEffect(() => {
    fetchInfo();
  }, [fetchInfo]);
  return (
    <div className="header-container my-3">

      <CModal visible={editCommentModel}  alignment="center">
        <CModalHeader closeButton={false}>
          <CModalTitle >Edit Comment</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>
            <input defaultValue={commentToEdit.text} className='form-control' onChange={(e) => setUpdatedComment(e.target.value)}/>
            <CButton className='mt-2 mr-2' onClick={updateComment}>
              {loader.flag && loader.button === 'update' ?
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                className="text-2xl"
              />: 'Update'}
            </CButton>
            <CButton className='mt-2 !bg-red-500 !border-red-500 ' onClick={deleteComment}>
            {loader.flag && loader.button === 'delete' ?
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                className="text-2xl"
              />: 'Delete'}
            </CButton>
            {msg.length > 0 && <center><h5 className='text-blue-500 text-lg mt-2'>{msg}</h5></center>}
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => {
              setEditCommentModel(false);
            }}
          >
            Close
          </CButton>
        </CModalFooter>
      </CModal>

      <center>
        <h4 className="Previous-comment">
          <p>Previous Comments</p>
        </h4>
      </center>
      <div className="flex flex-col space-y-1">
        {comments.map((comment) => (
          <div className="card px-1" key={comment.id}>
            <h5 className="card-title mr-6">{comment.text}</h5>
            <h6 className="card-subtitle mb-1 text-muted text-xs">
              By: {comment.created_by} <br />
              {dateConverter(comment.created_at)}
            </h6>

            <FontAwesomeIcon
              icon={faPen}
              className='absolute right-2 top-2' 
              onClick={() => editComment(comment)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
