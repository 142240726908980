import React, { useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import '../index.css';
import Navbar from '../components/Navbar';
import BackgroundHeader from '../components/BackgroundHeader';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome, faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import api from '../api/api';
import {
  CModal,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CButton,
} from '@coreui/react';

const UserProfile = () => {
  library.add(faHome, faUser);
  const [profile, setProfile] = useState(null);
  const [posts, setPosts] = useState([]);
  const [assignedStation, setAssignedStation] = useState('');
  const navigate = useNavigate();

  const [displaySidebar, setDisplaySidebar] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const [url, setUrl] = useState('');
  const [scoreNow, setScoreNow] = useState(false);
  const [onComplain, setonComplain] = useState();
  const [selectStation, setSelectStation] = useState();
  const [disableAccount, setDisableAccount] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [selectColony, setSelectColony] = useState();

  const fetchInfo = useCallback(async () => {
    try {
      const response = await api.get('/user/profile/', {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      });

      if (response.status === 200) {
        setProfile(response.data.user);
        setPosts(response.data.posts);
        setAssignedStation(response.data.user.station || 'Not Assigned');
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  }, []);

  useEffect(() => {
    fetchInfo();
  }, [fetchInfo]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 991) {
        setDisplaySidebar(false);
      } else {
        setDisplaySidebar(true);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSideBar = () => {
    setDisplaySidebar(!displaySidebar);
  };

  const logoutHandler = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem('userData'));
      await api.delete('/user/logout/', {
        data: { refresh_token: userData.refresh_token },
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      });
      localStorage.removeItem('userData');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('userType');
      localStorage.removeItem('username');
      navigate('/', { replace: true });
    } catch (error) {
      console.error('Logout error:', error);
      localStorage.clear();
      navigate('/', { replace: true });
    }
  };

  const verifyEmail = () => {
    const apiUrl = '';
    api
      .post(
        apiUrl,
        {
          email: profile.fields.email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': '{{ csrf_token }}',
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setEmailVerified(true);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const verifyPhone = () => {
    const apiUrl = '';
    api
      .post(
        apiUrl,
        {
          phone: profile.fields.phone,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': '{{ csrf_token }}',
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setPhoneVerified(true);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const DisableAccountFunc = () => {
    const apiUrl = '';
    api
      .post(
        apiUrl,
        {
          email: profile.fields.email,
          phone: profile.fields.phone,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': '{{ csrf_token }}',
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          logoutHandler();
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <div className="page-body pt-3">
      <CModal
        visible={disableAccount}
        onClose={() => setDisableAccount(false)}
        aria-labelledby="LiveDemoExampleLabel"
      >
        <CModalHeader onClose={() => setDisableAccount(false)}>
          <CModalTitle id="LiveDemoExampleLabel">Disable Account</CModalTitle>
        </CModalHeader>
        {profile && (
          <CModalBody>
            <input
              type="text"
              value={profile.email}
              readOnly="readonly"
              style={{ width: '250px', maxWidth: '70%' }}
            />
            <button
              className="btn btn-primary"
              style={{ marginLeft: '10px' }}
              onClick={verifyEmail}
            >
              verify
            </button>
            <input
              type="text"
              value={profile.phone}
              readOnly="readonly"
              style={{ width: '250px', maxWidth: '70%' }}
            />
            <button
              className="btn btn-primary"
              style={{ marginLeft: '10px' }}
              onClick={verifyPhone}
            >
              verify
            </button>
            <br />
            <center>
              <button
                className="btn btn-danger"
                style={{ marginTop: '20px' }}
                onClick={DisableAccountFunc}
                disabled={!emailVerified && !phoneVerified}
              >
                Disable
              </button>
            </center>
          </CModalBody>
        )}
        <CModalFooter>
          <CButton color="secondary" onClick={() => setDisableAccount(false)}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>

      <BackgroundHeader heading="Your Profile" subheading="Your Profile" />
      <div>
        <Navbar
          displaySidebar={displaySidebar}
          toggleSideBar={toggleSideBar}
          visibilityData={{ visibleModal, setVisibleModal }}
          urlData={{ url, setUrl }}
          scoreNowData={{ scoreNow, setScoreNow }}
          complainData={{ onComplain, setonComplain }}
          stationChange={{ selectStation, setSelectStation }}
          colonyChange={{ selectColony, setSelectColony }}
        />
      </div>
      <div
        className={`mt-[40px] ml-${
          displaySidebar ? (window.innerWidth > 991 ? '72' : '0') : '0'
        }`}
      >
        <div>
          <div className="container">
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-secondary mt-[-25px]"
                onClick={() => navigate('/EditProfile')}
              >
                Edit Profile
              </button>
            </div>
          </div>
        </div>
        <section className="user-section">
          <div className="container col-sm-12 col-md-6">
            <div className="card mb-4 mt-4 overflow-hidden border rounded">
              <div className="profile-body">
                {profile && (
                  <React.Fragment>
                    <ProfileRow
                      label="Name"
                      value={`${profile.first_name} ${profile.middle_name} ${profile.last_name}`}
                    />
                    <ProfileRow label="Phone" value={profile.phone} />
                    <ProfileRow label="Username" value={profile.username} />
                    <ProfileRow label="Email" value={profile.email} />
                    <ProfileRow
                      label="Joined Date"
                      value={new Date(profile.created_at).toLocaleDateString()}
                    />
                    <ProfileRow
                      label="Posts"
                      value={posts.join(', ') || 'No Posts Assigned'}
                    />
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

const ProfileRow = ({ label, value }) => (
  <React.Fragment>
    <div className="row">
      <div className="col-sm-3">
        <p className="mb-0">{label}</p>
      </div>
      <div className="col-sm-9">
        {/* Ensure no transformation here */}
        <p className="text-muted mb-0" style={{ textTransform: 'none' }}>
          {value}
        </p>
      </div>
    </div>
    <hr />
  </React.Fragment>
);

export default UserProfile;
