export const dateHotFix = (date, userData, viewDate) => {
  console.log(date, 'date', userData, 'userData', viewDate, 'viewDate');
  const station = userData.station_name;
  if (station === undefined) return date;
  switch (station) {
    case 'PNBE':
      if (viewDate === '2024-06-13') {
        const hotFixedDate = '2024-06-13';
        return hotFixedDate;
      } else if (viewDate === '2024-06-14') {
        const hotFixedDate = '2024-06-14';
        return hotFixedDate;
      } else {
        return date;
      }
    default:
      return date;
  }
};
