import React, { useCallback, useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import { useNavigate } from 'react-router-dom';
import api from '../api/api';
import { CModal, CModalBody, CModalFooter, CButton } from '@coreui/react';
import Select from 'react-select';

const ManageNotifiedUser = () => {
  const [displaySidebar, setDisplaySidebar] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectColony, setSelectColony] = useState(false);
  const [url, setUrl] = useState('');
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [errorModalFlag, setErrorModalFlag] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [showDetails, setShowDetails] = useState({ posts: [{ content: '' }] });
  const [showModal, setShowModal] = useState(false);
  const [showTableModal, setShowTableModal] = useState(false);
  const [userStatus, setUserStatus] = useState('');
  const [scoreNow, setScoreNow] = useState(false);
  const [onComplain, setOnComplain] = useState();
  const [selectStation, setSelectStation] = useState();
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
  const [newTaskName, setNewTaskName] = useState('');
  const [newTaskDescription, setNewTaskDescription] = useState('');
  const [stations, setStations] = useState([]);
  const [tasks, setTasks] = useState([]);
  const navigate = useNavigate();

  const toggleSideBar = () => {
    setDisplaySidebar(!displaySidebar);
  };

  const fetchInfo = useCallback(async () => {
    try {
      const response = await api.get('notified-user/get/', {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      });
      setData(response.data.data);
      setOriginalData(response.data.data);
    } catch (error) {
      setErrorModalFlag(true);
      setErrorMsg(error.message);
    }
  }, []);

  const fetchStations = useCallback(() => {
    const ret_stations = [];
    api
      .get('/station/stationslists/', {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      })
      .then((response) => {
        response.data.forEach((station) => {
          ret_stations.push({
            value: station.station_id.toString(),
            label: station.station_name,
          });
        });
        ret_stations.sort((a, b) => a.label.localeCompare(b.label));
        setStations(ret_stations);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const fetchTasks = useCallback(() => {
    api
      .get('/complain/complain?date=${formattedDate}', {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      })
      .then((response) => {
        const tasksData = response.data.tasks.map((task) => ({
          value: task[0].toString(),
          label: task[2],
        }));
        setTasks(tasksData);
      })
      .catch((error) => {
        console.error({ error });
      });
  }, []);

  useEffect(() => {
    fetchInfo();
    fetchStations();
    fetchTasks();
    const handleResize = () => {
      if (window.innerWidth < 991) {
        setDisplaySidebar(false);
      } else {
        setDisplaySidebar(true);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [fetchInfo, fetchStations, fetchTasks]);

  const handleUserStatusChange = async () => {
    setErrorModalFlag(false);
    const postsArray = Array.isArray(showDetails.posts)
      ? showDetails.posts
      : showDetails.posts.split(',').map((post) => ({ content: post.trim() }));
    const payload = {
      username: showDetails.username,
      whatsapp_number: showDetails.whatsapp_number,
      mobile_number: showDetails.mobile_number,
      email: showDetails.email,
      posts: postsArray,
      assigned_tasks: showDetails.assigned_tasks.map((task) => ({
        task_id: task.value,
        task_description: task.label,
      })),
      assigned_station: showDetails.assigned_station.map((station) => ({
        station_code: station.value,
        station_name: station.label,
      })),
    };
    try {
      const response = await api.patch(
        `notified-user/update/${showDetails.id}/`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': '{{ csrf_token }}',
          },
        }
      );
      if (response.status === 200) {
        setErrorModalFlag(true);
        setErrorMsg(response.data.message);
        fetchInfo();
        setShowModal(false);
      }
    } catch (error) {
      console.error('Error updating user status:', error);
    }
  };

  const userModal = (userDetails) => {
    setShowDetails({
      ...userDetails,
      assigned_tasks: tasks.filter((task) =>
        userDetails.assigned_tasks.some(
          (assignedTask) => assignedTask.task_id.toString() === task.value
        )
      ),
      assigned_station: stations.filter((station) =>
        userDetails.assigned_station.some(
          (assignedStation) =>
            assignedStation.station_code.toString() === station.value
        )
      ),
    });
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'posts') {
      const updatedPosts = value
        .split(',')
        .map((post) => ({ content: post.trim() }));
      setShowDetails((prevState) => ({
        ...prevState,
        posts: updatedPosts,
      }));
    } else {
      setShowDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSelectChange = (name, value) => {
    setShowDetails((prevState) => ({ ...prevState, [name]: value }));
  };

  const searchHandler = (searchValue) => {
    setSearchTerm(searchValue);
    const filteredUsers = originalData.filter(
      (user) =>
        (user.username &&
          user.username.toLowerCase().includes(searchValue.toLowerCase())) ||
        (user.email &&
          user.email.toLowerCase().includes(searchValue.toLowerCase())) ||
        (user.mobile_number && user.mobile_number.includes(searchValue))
    );
    setData(filteredUsers);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      try {
        if (document.querySelector('#station')) {
          document.querySelector('#station').destroy();
        }
      } catch (error) {
        console.log(error);
      }

      window.VirtualSelect.init({
        ele: '#station',
        search: true,
        optionSelectedText: 'Station Selected',
        optionsSelectedText: 'Stations Selected',
        allOptionsSelectedText: 'All Stations',
        searchPlaceholderText: 'Select all',
        alwaysShowSelectedOptionsCount: true,
      });

      if (document.querySelector('#station')) {
        document
          .querySelector('#station')
          .addEventListener('change', function () {
            handleSelectChange('assigned_station', this.getSelectedOptions());
          });
      }

      try {
        if (document.querySelector('#task')) {
          document.querySelector('#task').destroy();
        }
      } catch (error) {
        console.log(error);
      }

      window.VirtualSelect.init({
        ele: '#task',
        search: true,
        options: tasks,
        optionSelectedText: 'task Selected',
        optionsSelectedText: 'tasks Selected',
        allOptionsSelectedText: 'All tasks',
        searchPlaceholderText: 'Select all',
        alwaysShowSelectedOptionsCount: true,
      });

      if (document.querySelector('#task')) {
        document.querySelector('#task').addEventListener('change', function () {
          handleSelectChange('assigned_tasks', this.getSelectedOptions());
        });
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [showModal]);

  return (
    <div className="page-body">
      <CModal
        backdrop="static"
        alignment="center"
        visible={showModal}
        size="lg"
        aria-labelledby="ScoreNow"
      >
        <CModalBody>
          {showDetails && (
            <div className="col">
              <div className="card border rounded">
                <div className="card-body">
                  <div className="form-group">
                    <label>User Name:</label>
                    <input
                      type="text"
                      name="username"
                      value={showDetails.username}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label>Email:</label>
                    <input
                      type="email"
                      name="email"
                      value={showDetails.email}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label>Mobile Number:</label>
                    <input
                      type="text"
                      name="mobile_number"
                      value={showDetails.mobile_number}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label>WhatsApp Number:</label>
                    <input
                      type="text"
                      name="whatsapp_number"
                      value={showDetails.whatsapp_number}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label>Posts:</label>
                    <input
                      type="text"
                      name="posts"
                      value={showDetails.posts
                        .map((post) => post.content)
                        .join(', ')}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label>Assigned Tasks:</label>
                    {/* <Select
                      options={tasks}
                      value={showDetails.assigned_tasks}
                      onChange={(selectedOptions) => handleSelectChange('assigned_tasks', selectedOptions)}
                      isMulti
                      className="w-full p-1"
                    /> */}
                    <select
                      placeholder="Select tasks"
                      id="task"
                      multiple
                      className="multiselect mt-2 "
                      multiselect-search="true"
                      multiselect-select-all="true"
                      multiselect-max-items="1"
                      name="task"
                    >
                      {tasks.map((task) => (
                        <option
                          key={task.value}
                          value={task.value}
                          selected={
                            showDetails.assigned_tasks &&
                            showDetails.assigned_tasks.includes(task)
                          }
                        >
                          {task.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  {showDetails.assigned_tasks && (
                    <div>
                      <span className="text-gray-700 text-sm">
                        Selected tasks:{' '}
                      </span>
                      {showDetails.assigned_tasks.map((task) => (
                        <span className="text-gray-500 text-sm">
                          {task.label},{' '}
                        </span>
                      ))}
                    </div>
                  )}
                  <div className="form-group">
                    <label>Assigned Station:</label>
                    {/* <Select
                      options={stations}
                      value={showDetails.assigned_station}
                      onChange={(selectedOptions) => handleSelectChange('assigned_station', selectedOptions)}
                      isMulti
                      className="w-full p-1"
                    /> */}
                    <select
                      placeholder="Select Stations"
                      id="station"
                      multiple
                      className="multiselect mt-2 "
                      multiselect-search="true"
                      multiselect-select-all="true"
                      multiselect-max-items="1"
                      name="station"
                    >
                      {stations.map((station) => (
                        <option
                          key={station.value}
                          value={station.value}
                          selected={
                            showDetails.assigned_station &&
                            showDetails.assigned_station.includes(station)
                          }
                        >
                          {station.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  {showDetails.assigned_station && (
                    <div>
                      <span className="text-gray-700 text-sm">
                        Selected stations:{' '}
                      </span>
                      {showDetails.assigned_station.map((station) => (
                        <span className="text-gray-500 text-sm">
                          {station.label},{' '}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="w-full">
            <button
              type="button"
              onClick={handleUserStatusChange}
              className="btn-primary my-2 btn p-2 px-4 w-full"
            >
              Update User
            </button>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setShowModal(false)}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
      <Navbar
        displaySidebar={displaySidebar}
        toggleSideBar={toggleSideBar}
        visibilityData={{ visibleModal, setVisibleModal }}
        urlData={{ url, setUrl }}
        scoreNowData={{ scoreNow, setScoreNow }}
        complainData={{ onComplain, setOnComplain }}
        stationChange={{ selectStation, setSelectStation }}
        createTask={() => setShowCreateTaskModal(true)}
        colonyChange={{ selectColony, setSelectColony }}
      />
      <div
        style={{
          marginLeft:
            displaySidebar && window.innerWidth > 991 ? '230px' : '0px',
        }}
      >
        <div style={{ padding: '80px 40px' }}>
          <div className="header-container1 pb-2 max-sm:px-2 mb-1">
            <h4 className="text-center underline py-1 text-4xl">
              Notified Users Management
            </h4>
          </div>
          <div className="header-container1 pb-2 max-sm:px-2 mb-1">
            <button
              className="btn btn-primary p-2 w-full"
              onClick={() => navigate('/create-user', { replace: true })}
            >
              Create User
            </button>
          </div>
          <div className="header-container1 pb-2 max-sm:px-2 mb-1">
            <input
              type="text"
              placeholder="Search Username , Email or Mobile Number"
              value={searchTerm}
              onChange={(e) => searchHandler(e.target.value)}
              className="w-full p-2 border-2 border-gray-300 border-solid rounded-md"
            />
          </div>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 mt-1">
            {data.map((user, index) => (
              <div className="col max-h-20 h-20" key={index}>
                <div className="flex flex-col p-2 rounded border-2 border-green-500 max-h-20 h-20">
                  <div
                    className="card-body pointer cursor-pointer"
                    onClick={() => userModal(user)}
                  >
                    <span className="text-[0.875rem] font-bold flex flex-row space-x-1">
                      User Name: {user.username}
                    </span>
                    <span className="text-[0.875rem] font-bold flex flex-row space-x-1">
                      User's Current Station:
                      {user.assigned_station.length < 8
                        ? user.assigned_station
                            .map((station) => station.station_name)
                            .join(', ')
                        : user.assigned_station
                            .slice(0, 9)
                            .map((station) => station.station_name)
                            .join(', ') + '...'}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageNotifiedUser;
