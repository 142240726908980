import React, { useCallback, useEffect, useState } from 'react';
import BackgroundHeader from '../components/BackgroundHeader';
import Loader from '../Loader';
import ErrorModal from '../components/ErrorModal';
import Navbar from '../components/Navbar';
import api from '../api/api';
import { useNavigate } from 'react-router-dom';

function AccessColonies() {
  const [displaySidebar, setDisplaySidebar] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const [url, setUrl] = useState('');
  const [scoreNow, setScoreNow] = useState(false);
  const [selectColony, setSelectColony] = useState(false);
  const [onComplain, setonComplain] = useState();
  const [selectStation, setSelectStation] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [colonies, setColonies] = useState([]);

  const [errorModalFlag, setErrorModalFlag] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const toggleSideBar = () => {
    setDisplaySidebar(!displaySidebar);
  };

  const navigate = useNavigate();

  const handleColonyChange = (colony_name) => {
    api
      .get(`user/change_colony/${colony_name}`, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      })
      .then((response) => {
        if (response.data.message != null) {
          const userData = JSON.parse(localStorage.getItem('userData'));
          userData.colony_name = colony_name;
          userData.colony = response.data.colony_code;
          userData.station = null;
          userData.station_name = null;
          userData.station_category = null;
          localStorage.setItem('userData', JSON.stringify(userData));
          navigate('/Home', { replace: true });
          document.getElementById('stnmsg').style.backgroundColor = '#ccf1fd';
          document.getElementById(
            'stnmsg'
          ).innerHTML = `<p>${response.data.message}</p>`;
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchInfo = useCallback(async () => {
    setShowLoader(true);
    api
      .get('/user/access_colonies/', {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      })
      .then((response) => {
        setShowLoader(false);
        console.log(response.data.all_colonies);
        if (response.data.all_colonies.length === 0) {
          setErrorModalFlag(true);
          setErrorMsg('No colonies found');
        }
        setColonies(response.data.all_colonies);
      })
      .catch((error) => {
        setShowLoader(false);
        console.log(error);
        setErrorModalFlag(true);
        setErrorMsg(error.message);
      });
  }, []);

  useEffect(() => {
    fetchInfo();
    const handleResize = () => {
      if (window.innerWidth < 991) {
        setDisplaySidebar(false);
      } else {
        setDisplaySidebar(true);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [fetchInfo]);

  return (
    <div className="page-body">
      <div className="loader">{showLoader && <Loader />}</div>
      <BackgroundHeader />
      <div>
        <ErrorModal flag={errorModalFlag} message={errorMsg} />
        <Navbar
          displaySidebar={displaySidebar}
          toggleSideBar={toggleSideBar}
          visibilityData={{ visibleModal, setVisibleModal }}
          urlData={{ url, setUrl }}
          scoreNowData={{ scoreNow, setScoreNow }}
          complainData={{ onComplain, setonComplain }}
          stationChange={{ selectStation, setSelectStation }}
          colonyChange={{ selectColony, setSelectColony }}
        />
      </div>
      <div
        style={{
          marginLeft:
            displaySidebar === true
              ? window.innerWidth > 991
                ? '230px'
                : '0px'
              : '0px',
        }}
      >
        {colonies && colonies.length > 0 ? (
          <div className="flex flex-wrap justify-center">
            {colonies.map((colony, index) => (
              <div key={index} className="m-4">
                <p
                  className="bg-blue-500 text-white p-2  rounded-lg shadow-lg min-w-[72px] text-center flex justify-center items-center h-full"
                  onClick={() => handleColonyChange(colony.colony_name)}
                >
                  {colony.colony_name}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center items-center text-blue-400 min-h-screen">
          {colonies.length === 0 && !showLoader && 'No colonies found'}
          {showLoader && 'Loading....'}  
          </div>
        )}
      </div>
    </div>
  );
}

export default AccessColonies;
